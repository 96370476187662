import React, { useEffect, useState } from 'react'
import '../styles/NavbarJeunes.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { api, apiPost } from '../functions/api.js'
import NavBarJeune from './NavBarJeune.jsx'
import { Button, Collapse, Flex, Modal, Segmented, Spin } from 'antd'

import { toast } from 'react-toastify'
import { UserAddOutlined, UserOutlined, FileOutlined } from '@ant-design/icons'
import AjouterJeuneModal from './AjouterJeuneModal.jsx'

const NavbarJeunes = () => {
  const [jeunesActifs, setJeunesActifs] = useState([])
  const [jeunesAttentes, setJeunesAttentes] = useState([])
  const [jeunesArchives, setJeunesArchives] = useState([])
  const [cookies] = useCookies(['id', 'token', 'cours_fav'])
  const [itemOpen, setItemOpen] = useState(1)
  const [activeIndex, setActiveIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(true)
  const formDataInitial = {
    nom: '',
    prenom: '',
    referent: '',
    ecole_origine: '',
  }
  const [formData, setFormData] = useState(formDataInitial)
  const fetchJeunes = async () => {
    setFetchLoading(true)
    try {
      const response = await api('jeune/allSort/archive/0', {
        id: cookies['id'],
        token: cookies['token'],
      })
      setJeunesActifs(response.data.actifs)
      setJeunesAttentes(response.data.attentes)
      setJeunesArchives(response.data.archives)
    } catch (error) {
      console.error('Erreur lors de la récupération des jeunes:', error)
    } finally {
      setFetchLoading(false)
    }
  }

  const handleItemClick = (index) => {
    setActiveIndex(index) // Mettre à jour l'index de l'élément actif
  }
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)

    //fetchProfs()
  }
  const handleOk = async () => {
    setIsModalVisible(false)
    try {
      const response = await apiPost(
        'jeune/create',
        {
          id: cookies['id'],
          token: cookies['token'],
          'Content-Type': 'application/json',
        },
        formData
      )
      if (response.status === 200) {
        toast.success(response.data.message)
        setFormData(formDataInitial)
        fetchJeunes()
      } else {
        console.error('Erreur:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
    }
  }
  const navigate = useNavigate()
  const handleOkEdit = async () => {
    setIsModalVisible(false)
    try {
      const response = await apiPost(
        'jeune/create',
        {
          id: cookies['id'],
          token: cookies['token'],
          'Content-Type': 'application/json',
        },
        formData
      )
      if (response.status === 200) {
        const jeuneId = response.data.id // Supposons que l'id du jeune soit dans response.data.jeune.id
        fetchJeunes()
        navigate(`/Jeune/${jeuneId}/1`)
      } else {
        console.error('Erreur:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
    }
  }
  const handleCancel = () => setIsModalVisible(false)
  const optionsJeunes = [
    {
      key: 'actifs',
      label: 'Les jeunes actifs',
      children: fetchLoading ? null : (
        <Collapse
          accordion
          ghost
          size="small"
          items={jeunesActifs.map((jeune) => ({
            key: jeune.id,
            label:
              jeune.nom.toUpperCase() +
              ' ' +
              jeune.prenom.charAt(0).toUpperCase() +
              jeune.prenom.slice(1).toLowerCase(),
            children: <NavBarJeune jeune={jeune} />,
          }))}
        />
      ),
    },
    {
      key: 'attentes',
      label: 'Les jeunes en attentes',
      children: fetchLoading ? null : (
        <Collapse
          accordion
          ghost
          size="small"
          items={jeunesAttentes.map((jeune) => ({
            key: jeune.id,
            label:
              jeune.nom.toUpperCase() +
              ' ' +
              jeune.prenom.charAt(0).toUpperCase() +
              jeune.prenom.slice(1).toLowerCase(),
          }))}
        />
      ),
    },
    {
      key: 'archives',
      label: 'Les jeunes archivés',
      children: fetchLoading ? null : (
        <Collapse
          accordion
          ghost
          size="small"
          items={jeunesArchives.map((jeune) => ({
            key: jeune.id,
            label:
              jeune.nom.toUpperCase() +
              ' ' +
              jeune.prenom.charAt(0).toUpperCase() +
              jeune.prenom.slice(1).toLowerCase(),
          }))}
        />
      ),
    },
  ]
  useEffect(() => {
    fetchJeunes()
  }, [])

  return cookies['id'] && cookies['token'] ? (
    <nav className="navbarJeunes" onMouseEnter={fetchJeunes}>
      {fetchLoading ? null : (
        <Flex vertical>
          <Button
            color="default"
            variant="outlined"
            className="jeunesOpen"
            onClick={showModal}
            icon={<UserAddOutlined />}
            ghost
          >
            Ajouter un jeune
          </Button>
          <Collapse
            ghost
            size="large"
            items={optionsJeunes}
            accordion
            defaultActiveKey={'actifs'}
            className="jeunesOpen"
          />
          <Flex vertical className="jeunesClose" align="center" justify="center">
            <UserOutlined style={{ fontSize: 30 }} />
          </Flex>
          <Modal
            title="Ajouter un jeune"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Annuler
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                Sauvegarder et fermer
              </Button>,
              <Button type="primary" loading={loading} onClick={handleOkEdit}>
                Sauvegarder et éditer
              </Button>,
            ]}
          >
            <AjouterJeuneModal formData={formData} setFormData={setFormData} />
          </Modal>
        </Flex>
      )}
    </nav>
  ) : null
}

export default NavbarJeunes
