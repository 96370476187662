import React, { useCallback, useMemo, useState } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import { css } from '@emotion/css'

const A4PageStyles = css`
  width: 210mm;
  height: 297mm;
  padding: 20mm;
  margin: auto;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const FullScreenContainer = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`
const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: 'A line of text in a paragraph.' }],
  },
]
const EditeurDeTexte = () => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [value, setValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: 'Écrivez votre contenu ici...' }],
    },
  ])

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      default:
        return <p {...props.attributes}>{props.children}</p>
    }
  }, [])

  return (
    <div className={FullScreenContainer}>
      <div className={A4PageStyles}>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={(newValue) => setValue(newValue)}
        >
          <Editable renderElement={renderElement} />
        </Slate>
      </div>
    </div>
  )
}

export default EditeurDeTexte
