// dateUtils.js

export function formaterDate(dateString, format) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const dateObj = new Date(dateString)

  if (format === 'DD/MM/YYYY') {
    const jour = String(dateObj.getDate()).padStart(2, '0')
    const mois = String(dateObj.getMonth() + 1).padStart(2, '0') // Attention, le mois commence à 0
    const annee = dateObj.getFullYear()
    return `${jour}/${mois}/${annee}`
  } else if (format === 'mots') {
    return dateObj.toLocaleDateString('fr-FR', options)
  } else {
    // Format non reconnu, retourner la date d'origine
    return dateString
  }
}
