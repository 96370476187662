import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import Navbar2 from './components/Navbar2.jsx'

import './styles/main.scss'
import './styles/contentPage.scss'
import './styles/Modal.scss'

import Main from './components/Main.jsx'
import NavbarJeunes from './components/NavbarJeunes.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <GoogleOAuthProvider clientId="613698822478-1r22ahfcp2u8mgh5jjt7t6ugdc52rjtu.apps.googleusercontent.com">
    <React.StrictMode>
      <Router>
        <ToastContainer
          autoClose={3000}
          pauseOnHover={false}
          position="bottom-center"
        />
        <Main />
        <Navbar2 />
        <NavbarJeunes />
      </Router>
    </React.StrictMode>
  </GoogleOAuthProvider>
)
