import React from 'react'
import { formaterTelephone } from '../functions/telephoneUtils'
import { Link } from 'react-router-dom'
import { EditOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import '../styles/NavbarJeunes.scss'
import { Flex } from 'antd'
const NavBarJeune = ({ jeune }) => {
  const elementStyle = { padding: 2 }
  return (
    <ul style={{ color: 'white', listStyle: 'none', marginLeft: 16 }}>
      <li style={elementStyle}>
        <Flex justify="space-between">
          <Flex className="itemType">
            <PhoneOutlined />
            <span className="link_normal">
              {formaterTelephone(jeune.telephone, 'xxxx/xx.xx.xx')}
            </span>
          </Flex>
          <Link className="itemAction" to={`Jeune/${jeune.id}/1`}>
            <EditOutlined style={{ fontSize: 12 }} />
          </Link>
        </Flex>
      </li>
      <li style={elementStyle}>
        <MailOutlined />
        <span className="link_normal">{jeune.email}</span>
      </li>
      <li style={elementStyle}></li>
      <li style={elementStyle}>
        <Link to={`Jeune/${jeune.id}/1`}>
          <EditOutlined />
          <span className="link_normal">editer</span>
        </Link>
      </li>
    </ul>
  )
}

export default NavBarJeune
