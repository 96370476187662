import { Checkbox } from 'antd'
import React, { useState, useEffect } from 'react'

const AbsencesCheckbox = ({ abs, periode, jeune, code, setCode, date, periodesCount }) => {
  const getInitialChecked = () => {
    return code.some(
      (absence) =>
        absence.eleve_id === jeune &&
        absence.code.some((entry) => entry.periode === periode && entry.absId === abs && entry.checked)
    )
  }

  const [checked, setChecked] = useState(getInitialChecked)

  useEffect(() => {
    setChecked(getInitialChecked())
  }, [code])

  const handleChange = (e) => {
    const isChecked = e.target.checked
    updateCode(isChecked)
  }

  const handleDoubleClick = () => {
    // Inverse l’état `checked` actuel de toutes les cases ayant le même `absId`
    const allChecked = code.some(
      (absence) => absence.eleve_id === jeune && absence.code.some((entry) => entry.absId === abs && entry.checked)
    )

    updateCode(!allChecked, true) // Inverse toutes les cases de même `absId` pour le jeune
  }

  const updateCode = (isChecked, updateAll = false) => {
    const updatedCode = [...code]
    const absenceIndex = updatedCode.findIndex((absence) => absence.eleve_id === jeune)

    if (absenceIndex !== -1) {
      const absenceEntries = updatedCode[absenceIndex].code.filter((entry) => entry.absId === abs)

      if (updateAll) {
        // Met à jour toutes les périodes de cet `absId`
        const updatedEntries = Array.from({ length: periodesCount }, (_, i) => ({
          periode: i,
          absId: abs,
          checked: isChecked,
        }))

        updatedCode[absenceIndex].code = [
          ...updatedCode[absenceIndex].code.filter((entry) => entry.absId !== abs),
          ...updatedEntries,
        ]
      } else {
        // Met à jour une seule période
        const entryIndex = updatedCode[absenceIndex].code.findIndex(
          (entry) => entry.periode === periode && entry.absId === abs
        )

        if (entryIndex !== -1) {
          updatedCode[absenceIndex].code[entryIndex].checked = isChecked
        } else {
          updatedCode[absenceIndex].code.push({ periode, absId: abs, checked: isChecked })
        }
      }
    } else {
      // Ajoute une nouvelle entrée pour le jeune et l’absence
      const newAbsences = updateAll
        ? Array.from({ length: periodesCount }, (_, i) => ({
            periode: i,
            absId: abs,
            checked: isChecked,
          }))
        : [{ periode, absId: abs, checked: isChecked }]

      updatedCode.push({
        eleve_id: jeune,
        date: date,
        code: newAbsences,
      })
    }

    setCode(updatedCode)
    setChecked(isChecked)
  }

  return <Checkbox checked={checked} onChange={handleChange} onDoubleClick={handleDoubleClick} />
}

export default AbsencesCheckbox
