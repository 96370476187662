import React, { useEffect, useState } from 'react'
import Titre from './Titre.jsx'
import { api, apiDelete, apiPost, apiPut } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import {
  Button,
  Collapse,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'

dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const ParametresAbsences = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'id',
    'token',
    'cours_fav',
  ])

  const [absencesGroupes, setAbsencesGroupes] = useState([])
  const [absencesTypes, setAbsencesTypes] = useState([])
  const [openModalGroupes, setOpenModalGroupes] = useState(false)
  const [openModalTypes, setOpenModalTypes] = useState(false)
  const [absencesGroupesEdit, setAbsencesGroupesEdit] = useState({})
  const [absencesTypesEdit, setAbsencesTypesEdit] = useState({})
  const [loadingGroupes, setLoadingGroupes] = useState(true)
  const [loadingTypes, setLoadingTypes] = useState(true)
  const [availableColors, setAvailableColors] = useState([])
  const [availableColorShades, setAvailableColorShades] = useState([])
  const columnsAbsencesGroupes = [
    {
      title: 'Intitulé',
      dataIndex: 'intitule',
      key: 'intitule',
    },
    {
      title: 'Couleur',
      dataIndex: 'color',
      key: 'color',
      render: (_, record) => (
        <span
          style={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            backgroundColor: record.color,
            marginRight: '5px',
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editAbsencesGroupes(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deleteAbsencesGroupes(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsAbsencesTypes = [
    {
      title: 'Intitulé',
      dataIndex: 'intitule',
      key: 'intitule',
    },
    {
      title: 'Groupe',
      dataIndex: 'groupe',
      key: 'groupe',
      render: (_, record) => {
        const groupe = absencesGroupes.find(
          (item) => item.id === record.groupe_id
        )
        return groupe ? groupe.intitule : 'Non trouvé'
      },
    },
    {
      title: 'Couleur',
      dataIndex: 'color',
      key: 'color',
      render: (_, record) => (
        <span
          style={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            backgroundColor: record.color,
            marginRight: '5px',
          }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editAbsencesTypes(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deleteAbsencesTypes(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]

  // Tableau des couleurs classiques
  const colors = [
    {
      key: 'blue',
      value: '#0000FF',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#0000FF',
              marginRight: '5px',
            }}
          />{' '}
          Bleu
        </span>
      ),
    },
    {
      key: 'red',
      value: '#FF0000',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF0000',
              marginRight: '5px',
            }}
          />{' '}
          Rouge
        </span>
      ),
    },
    {
      key: 'green',
      value: '#008000',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#008000',
              marginRight: '5px',
            }}
          />{' '}
          Vert
        </span>
      ),
    },
    {
      key: 'yellow',
      value: '#FFFF00',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFFF00',
              marginRight: '5px',
            }}
          />{' '}
          Jaune
        </span>
      ),
    },
    {
      key: 'orange',
      value: '#FFA500',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFA500',
              marginRight: '5px',
            }}
          />{' '}
          Orange
        </span>
      ),
    },
    {
      key: 'purple',
      value: '#800080',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#800080',
              marginRight: '5px',
            }}
          />{' '}
          Violet
        </span>
      ),
    },
    {
      key: 'pink',
      value: '#FFC0CB',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFC0CB',
              marginRight: '5px',
            }}
          />{' '}
          Rose
        </span>
      ),
    },
  ]

  // Tableau des nuances pour chaque couleur
  const colorShades = [
    // Nuances de bleu
    {
      colorKey: 'blue',
      key: 'blue-lightest',
      value: '#E0FFFF',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#E0FFFF',
              marginRight: '5px',
            }}
          />{' '}
          Bleu clair (plus clair)
        </span>
      ),
    },
    {
      colorKey: 'blue',
      key: 'blue-lighter',
      value: '#ADD8E6',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#ADD8E6',
              marginRight: '5px',
            }}
          />{' '}
          Bleu clair
        </span>
      ),
    },
    {
      colorKey: 'blue',
      key: 'blue-light',
      value: '#87CEFA',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#87CEFA',
              marginRight: '5px',
            }}
          />{' '}
          Bleu ciel
        </span>
      ),
    },
    {
      colorKey: 'blue',
      key: 'blue-muted',
      value: '#4682B4',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#4682B4',
              marginRight: '5px',
            }}
          />{' '}
          Bleu gris
        </span>
      ),
    },
    {
      colorKey: 'blue',
      key: 'blue-dark',
      value: '#00008B',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#00008B',
              marginRight: '5px',
            }}
          />{' '}
          Bleu foncé
        </span>
      ),
    },
    {
      colorKey: 'blue',
      key: 'blue-darker',
      value: '#000080',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#000080',
              marginRight: '5px',
            }}
          />{' '}
          Bleu marine
        </span>
      ),
    },

    // Nuances de rouge
    {
      colorKey: 'red',
      key: 'red-lightest',
      value: '#FFD1D1',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFD1D1',
              marginRight: '5px',
            }}
          />{' '}
          Rouge très clair
        </span>
      ),
    },
    {
      colorKey: 'red',
      key: 'red-lighter',
      value: '#FFB6C1',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFB6C1',
              marginRight: '5px',
            }}
          />{' '}
          Rouge clair
        </span>
      ),
    },
    {
      colorKey: 'red',
      key: 'red-light',
      value: '#FFA07A',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFA07A',
              marginRight: '5px',
            }}
          />{' '}
          Rouge saumon
        </span>
      ),
    },
    {
      colorKey: 'red',
      key: 'red-muted',
      value: '#CD5C5C',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#CD5C5C',
              marginRight: '5px',
            }}
          />{' '}
          Rouge terne
        </span>
      ),
    },
    {
      colorKey: 'red',
      key: 'red-dark',
      value: '#8B0000',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#8B0000',
              marginRight: '5px',
            }}
          />{' '}
          Rouge foncé
        </span>
      ),
    },
    {
      colorKey: 'red',
      key: 'red-darker',
      value: '#800000',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#800000',
              marginRight: '5px',
            }}
          />{' '}
          Rouge bordeaux
        </span>
      ),
    },

    // Nuances de vert
    {
      colorKey: 'green',
      key: 'green-lightest',
      value: '#E0FFE0',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#E0FFE0',
              marginRight: '5px',
            }}
          />{' '}
          Vert pâle
        </span>
      ),
    },
    {
      colorKey: 'green',
      key: 'green-lighter',
      value: '#98FB98',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#98FB98',
              marginRight: '5px',
            }}
          />{' '}
          Vert clair
        </span>
      ),
    },
    {
      colorKey: 'green',
      key: 'green-light',
      value: '#90EE90',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#90EE90',
              marginRight: '5px',
            }}
          />{' '}
          Vert herbe
        </span>
      ),
    },
    {
      colorKey: 'green',
      key: 'green-muted',
      value: '#556B2F',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#556B2F',
              marginRight: '5px',
            }}
          />{' '}
          Vert olive
        </span>
      ),
    },
    {
      colorKey: 'green',
      key: 'green-dark',
      value: '#006400',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#006400',
              marginRight: '5px',
            }}
          />{' '}
          Vert foncé
        </span>
      ),
    },
    {
      colorKey: 'green',
      key: 'green-darker',
      value: '#004d00',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#004d00',
              marginRight: '5px',
            }}
          />{' '}
          Vert sapin
        </span>
      ),
    },

    // Nuances de jaune
    {
      colorKey: 'yellow',
      key: 'yellow-lightest',
      value: '#FFFFE0',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFFFE0',
              marginRight: '5px',
            }}
          />{' '}
          Jaune pâle
        </span>
      ),
    },
    {
      colorKey: 'yellow',
      key: 'yellow-lighter',
      value: '#FFFACD',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFFACD',
              marginRight: '5px',
            }}
          />{' '}
          Jaune clair
        </span>
      ),
    },
    {
      colorKey: 'yellow',
      key: 'yellow-light',
      value: '#FFEB3B',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFEB3B',
              marginRight: '5px',
            }}
          />{' '}
          Jaune vif
        </span>
      ),
    },
    {
      colorKey: 'yellow',
      key: 'yellow-muted',
      value: '#FFD700',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFD700',
              marginRight: '5px',
            }}
          />{' '}
          Jaune doré
        </span>
      ),
    },
    {
      colorKey: 'yellow',
      key: 'yellow-dark',
      value: '#FFA500',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFA500',
              marginRight: '5px',
            }}
          />{' '}
          Jaune foncé
        </span>
      ),
    },
    {
      colorKey: 'yellow',
      key: 'yellow-darker',
      value: '#FF8C00',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF8C00',
              marginRight: '5px',
            }}
          />{' '}
          Orange foncé
        </span>
      ),
    },

    // Nuances d'orange
    {
      colorKey: 'orange',
      key: 'orange-lightest',
      value: '#FFE4B5',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFE4B5',
              marginRight: '5px',
            }}
          />{' '}
          Orange pâle
        </span>
      ),
    },
    {
      colorKey: 'orange',
      key: 'orange-lighter',
      value: '#FFDAB9',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFDAB9',
              marginRight: '5px',
            }}
          />{' '}
          Orange pêche
        </span>
      ),
    },
    {
      colorKey: 'orange',
      key: 'orange-light',
      value: '#FFA07A',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFA07A',
              marginRight: '5px',
            }}
          />{' '}
          Orange clair
        </span>
      ),
    },
    {
      colorKey: 'orange',
      key: 'orange-muted',
      value: '#FF7F50',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF7F50',
              marginRight: '5px',
            }}
          />{' '}
          Orange corail
        </span>
      ),
    },
    {
      colorKey: 'orange',
      key: 'orange-dark',
      value: '#FF4500',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF4500',
              marginRight: '5px',
            }}
          />{' '}
          Orange vif
        </span>
      ),
    },
    {
      colorKey: 'orange',
      key: 'orange-darker',
      value: '#FF6347',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF6347',
              marginRight: '5px',
            }}
          />{' '}
          Tomate
        </span>
      ),
    },

    // Nuances de violet
    {
      colorKey: 'purple',
      key: 'purple-lightest',
      value: '#E6E6FA',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#E6E6FA',
              marginRight: '5px',
            }}
          />{' '}
          Lavande
        </span>
      ),
    },
    {
      colorKey: 'purple',
      key: 'purple-lighter',
      value: '#D8BFD8',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#D8BFD8',
              marginRight: '5px',
            }}
          />{' '}
          Violet clair
        </span>
      ),
    },
    {
      colorKey: 'purple',
      key: 'purple-light',
      value: '#DA70D6',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#DA70D6',
              marginRight: '5px',
            }}
          />{' '}
          Orchidée
        </span>
      ),
    },
    {
      colorKey: 'purple',
      key: 'purple-muted',
      value: '#800080',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#800080',
              marginRight: '5px',
            }}
          />{' '}
          Violet foncé
        </span>
      ),
    },
    {
      colorKey: 'purple',
      key: 'purple-dark',
      value: '#4B0082',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#4B0082',
              marginRight: '5px',
            }}
          />{' '}
          Indigo
        </span>
      ),
    },
    {
      colorKey: 'purple',
      key: 'purple-darker',
      value: '#2E0854',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#2E0854',
              marginRight: '5px',
            }}
          />{' '}
          Bleu nuit
        </span>
      ),
    },

    // Nuances de rose
    {
      colorKey: 'pink',
      key: 'pink-lightest',
      value: '#FFE4E1',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFE4E1',
              marginRight: '5px',
            }}
          />{' '}
          Rose très pâle
        </span>
      ),
    },
    {
      colorKey: 'pink',
      key: 'pink-lighter',
      value: '#FFB6C1',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FFB6C1',
              marginRight: '5px',
            }}
          />{' '}
          Rose clair
        </span>
      ),
    },
    {
      colorKey: 'pink',
      key: 'pink-light',
      value: '#FF69B4',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF69B4',
              marginRight: '5px',
            }}
          />{' '}
          Rose vif
        </span>
      ),
    },
    {
      colorKey: 'pink',
      key: 'pink-muted',
      value: '#FF1493',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#FF1493',
              marginRight: '5px',
            }}
          />{' '}
          Rose profond
        </span>
      ),
    },
    {
      colorKey: 'pink',
      key: 'pink-dark',
      value: '#C71585',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#C71585',
              marginRight: '5px',
            }}
          />{' '}
          Rose foncé
        </span>
      ),
    },
    {
      colorKey: 'pink',
      key: 'pink-darker',
      value: '#8B008B',
      label: (
        <span>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: '#8B008B',
              marginRight: '5px',
            }}
          />{' '}
          Fuchsia
        </span>
      ),
    },
  ]

  const fetchAbsencesGroupes = async () => {
    try {
      const response = await api(`absencegroupe/all`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setAbsencesGroupes(response.data)
      setLoadingGroupes(false)
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des absences groupes:',
        error
      )
    }
  }

  const saveAbsencesGroupes = async () => {
    if (absencesGroupesEdit.id === 'new') {
      try {
        await toast.promise(
          apiPost(
            'absencegroupe/create',
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            absencesGroupesEdit
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalGroupes(false)
        fetchAbsencesGroupes()
      }
    } else {
      try {
        await toast.promise(
          apiPost(
            `absencegroupe/update/${absencesGroupesEdit.id}`,
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            absencesGroupesEdit
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalGroupes(false)
        fetchAbsencesGroupes()
      }
    }
  }
  const editAbsencesGroupes = (groupe) => {
    setAbsencesGroupesEdit(groupe)
    setOpenModalGroupes(true)
  }
  const deleteAbsencesGroupes = async (groupeId) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        api(`absencegroupe/delete/${groupeId}`, {
          id: cookies['id'],
          token: cookies['token'],
        }),
        {
          pending: 'Suppression en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${
                data?.response?.data?.message || 'Veuillez réessayer.'
              }`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la suppression de la date: ', error)
    } finally {
      fetchAbsencesGroupes()
    }
  }
  const fetchAbsencesTypes = async () => {
    try {
      const response = await api(`absencetype/all`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setAbsencesTypes(response.data)
      setLoadingTypes(false)
    } catch (error) {
      console.error('Erreur lors de la récupération des absences types:', error)
    }
  }

  const saveAbsencesTypes = async () => {
    if (absencesTypesEdit.id === 'new') {
      try {
        await toast.promise(
          apiPost(
            'absencetype/create',
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            absencesTypesEdit
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalTypes(false)
        fetchAbsencesTypes()
      }
    } else {
      try {
        await toast.promise(
          apiPost(
            `absencetype/update/${absencesTypesEdit.id}`,
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            absencesTypesEdit
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalTypes(false)
        fetchAbsencesTypes()
      }
    }
  }
  const editAbsencesTypes = (type) => {
    setAbsencesTypesEdit(type)
    setOpenModalTypes(true)
  }
  const deleteAbsencesTypes = async (groupeId) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        api(
          `absencetype/delete/${groupeId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { id: groupeId }
        ),
        {
          pending: 'Suppression en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${
                data?.response?.data?.message || 'Veuillez réessayer.'
              }`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la suppression de la date: ', error)
    } finally {
      fetchAbsencesTypes()
    }
  }
  useEffect(() => {
    fetchAbsencesGroupes()
    fetchAbsencesTypes()
  }, [])
  useEffect(() => {
    const usedColors = absencesGroupes.map((group) => group.color)
    setAvailableColors(
      colors.filter((color) => !usedColors.includes(color.value))
    )
  }, [absencesGroupes])

  useEffect(() => {
    // Étape 1 : Récupérer la couleur depuis absencesGroupes
    const group = absencesGroupes.find(
      (groupe) => groupe.id === absencesTypesEdit.groupe_id
    )
    if (group) {
      const { color } = group // La couleur correspondante

      // Étape 2 : Trouver la clé associée à cette couleur dans le tableau colors
      const colorObject = colors.find((c) => c.value === color)
      if (colorObject) {
        const colorKey = colorObject.key // La clé associée

        // Étape 3 : Filtrer les nuances dans colorShades en fonction de la colorKey
        let filteredShades = colorShades.filter(
          (shade) => shade.colorKey === colorKey
        )

        // Étape 4 : Retirer les objets ayant une couleur déjà présente dans absencesTypes
        const usedColors = absencesTypes.map((type) => type.color)
        filteredShades = filteredShades.filter(
          (shade) => !usedColors.includes(shade.value)
        )

        // Mettre à jour le state availableColorShades
        setAvailableColorShades(filteredShades)
      }
    }
  }, [absencesTypesEdit.groupe_id])

  return (
    <Flex vertical>
      {!loadingGroupes ? (
        <Flex vertical>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={3}
          >
            Les catégories des types d'absences
          </Typography.Title>
          <div>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={() => {
                setAbsencesGroupesEdit({
                  id: 'new',
                  intitule: '',
                  color: '',
                })
                setOpenModalGroupes(true)
              }}
            >
              Ajouter un groupe d'absences
            </Button>
          </div>
          <Modal
            open={openModalGroupes}
            destroyOnClose={true}
            onOk={saveAbsencesGroupes}
            onCancel={() => setOpenModalGroupes(false)}
            width={600}
          >
            <Typography.Title
              style={{
                color: '#6a778e',
                width: '100%',
              }}
              level={3}
            >
              Ajouter un groupe d'absences
            </Typography.Title>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                L'intitulé du groupe
              </Typography.Title>
              <Input
                value={
                  absencesGroupesEdit.intitule
                    ? absencesGroupesEdit.intitule
                    : null
                }
                placeholder="Absences, Ecole d'origine, Présences..."
                style={{ width: '100%' }}
                onChange={(e) =>
                  setAbsencesGroupesEdit({
                    ...absencesGroupesEdit,
                    intitule: e.target.value,
                  })
                }
              />
            </Space>
            <Space
              direction="vertical"
              style={{ width: '100%', marginTop: 15 }}
            >
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                La couleur du groupe
              </Typography.Title>
              <Select
                options={availableColors}
                style={{ width: '100%' }}
                placeholder="Selectionne une couleur"
                value={
                  absencesGroupesEdit.color ? absencesGroupesEdit.color : null
                }
                onChange={(e) =>
                  setAbsencesGroupesEdit({
                    ...absencesGroupesEdit,
                    color: e,
                  })
                }
              />
            </Space>
          </Modal>
          <Table
            bordered
            columns={columnsAbsencesGroupes}
            dataSource={absencesGroupes}
          />
        </Flex>
      ) : (
        <Spin />
      )}
      {!loadingTypes ? (
        <Flex vertical>
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={3}
          >
            Les types d'absences
          </Typography.Title>
          <div>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={() => {
                setAbsencesTypesEdit({
                  id: 'new',
                  intitule: '',
                  color: '',
                  groupe_id: null,
                })
                setOpenModalTypes(true)
              }}
            >
              Ajouter un type d'absences
            </Button>
          </div>
          <Modal
            open={openModalTypes}
            destroyOnClose={true}
            width={600}
            onCancel={() => setOpenModalTypes(false)}
            footer={[
              <Button key="back" onClick={() => setOpenModalTypes(false)}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={saveAbsencesTypes}
                disabled={absencesTypesEdit.groupe_id === null}
              >
                OK
              </Button>,
            ]}
          >
            <Typography.Title
              style={{
                color: '#6a778e',
                width: '100%',
              }}
              level={3}
            >
              Ajouter un type d'absences
            </Typography.Title>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                Dans quel groupe ?
              </Typography.Title>
              <Select
                options={absencesGroupes.map((groupe) => ({
                  value: groupe.id,
                  label: groupe.intitule,
                }))}
                style={{ width: '100%' }}
                placeholder="Selectionne un groupe"
                value={
                  absencesTypesEdit.groupe_id
                    ? absencesTypesEdit.groupe_id
                    : null
                }
                onChange={(e) =>
                  setAbsencesTypesEdit({
                    ...absencesTypesEdit,
                    groupe_id: e,
                  })
                }
              />
            </Space>
            <Space
              direction="vertical"
              style={{ width: '100%', marginTop: 15 }}
            >
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                L'intitulé du type
              </Typography.Title>
              <Input
                value={
                  absencesTypesEdit.intitule ? absencesTypesEdit.intitule : null
                }
                placeholder="Absences, Ecole d'origine, Présences..."
                style={{ width: '100%' }}
                onChange={(e) =>
                  setAbsencesTypesEdit({
                    ...absencesTypesEdit,
                    intitule: e.target.value,
                  })
                }
                disabled={absencesTypesEdit.groupe_id === null}
              />
            </Space>
            <Space
              direction="vertical"
              style={{ width: '100%', marginTop: 15 }}
            >
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                La couleur du type
              </Typography.Title>
              <Select
                options={availableColorShades}
                style={{ width: '100%' }}
                placeholder="Selectionne une couleur"
                value={absencesTypesEdit.color ? absencesTypesEdit.color : null}
                disabled={absencesTypesEdit.groupe_id === null}
                onChange={(e) =>
                  setAbsencesTypesEdit({
                    ...absencesTypesEdit,
                    color: e,
                  })
                }
              />
            </Space>
          </Modal>
          <Table
            bordered
            columns={columnsAbsencesTypes}
            dataSource={absencesTypes}
          />
        </Flex>
      ) : (
        <Spin />
      )}
    </Flex>
  )
}

export default ParametresAbsences
