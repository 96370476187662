import axios from 'axios'
const urlDefault = process.env.REACT_APP_API_URL
const urlEcole = process.env.REACT_APP_ECOLE_API
export async function api(url, headersToSend) {
  const response = await axios.get(urlDefault + url, {
    headers: headersToSend,
  })
  return response
}
export async function apiPost(url, headersToSend, datasToSend) {
  const response = await axios.post(urlDefault + url, datasToSend, {
    headers: headersToSend,
  })
  return response
}
export async function apiPut(url, headersToSend, datasToSend) {
  const response = await axios.put(urlDefault + url, datasToSend, {
    headers: headersToSend,
  })
  return response
}
export async function apiDelete(url, headersToSend) {
  const response = await axios.delete(urlDefault + url, {
    headers: headersToSend,
  })
  return response
}
export async function apiEcole(type, nom) {
  const dataUrl = `where=niveau%20%3D%20%22${encodeURIComponent(
    type
  )}%22%20and%20search(nom_de_l_etablissement,%22${encodeURIComponent(nom)}%22)%20&limit=100`
  const response = await fetch(urlEcole + dataUrl)
  return response
}
