import React from 'react'
import '../styles/Titre.scss'
const Titre = ({ children }) => {
  return (
    <div className="Titre">
      {typeof children === 'string' ? <h1>{children}</h1> : children}
    </div>
  )
}

export default Titre
