// dateUtils.js

export function formaterTelephone(numero, format) {
  // Nettoyer le numéro de téléphone pour supprimer les caractères non numériques
  const cleaned = ('' + numero).replace(/\D/g, '')

  // Variable pour stocker l'index du caractère à ajouter
  let index = 0

  // Remplacer chaque caractère 'x' dans le format par le chiffre correspondant du numéro
  const formatted = format.replace(/x/g, () => {
    return cleaned[index++] || ''
  })

  return formatted
}
