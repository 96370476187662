import React, { useEffect, useState } from 'react'
import '../styles/Variables.scss'
import Titre from '../components/Titre.jsx'
import dayjs from 'dayjs'
import { Checkbox, ConfigProvider, DatePicker, Flex, Spin, Table } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { api, apiPost } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import isoWeek from 'dayjs/plugin/isoWeek'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
import { addWeeks, addDays, format, isSameWeek } from 'date-fns'
import { toast } from 'react-toastify'
import AbsencesCheckbox from '../components/AbsencesCheckbox.jsx'
import '../styles/AbsencesEncodage.scss'
dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
dayjs.extend(isoWeek)
const periodesParJours = {
  lundi: 6,
  mardi: 6,
  mercredi: 4,
  jeudi: 6,
  vendredi: 6,
}

const initialAbsences = [
  { jeune_id: 1, periode: 1, type_id: 2 },
  { jeune_id: 2, periode: 3, type_id: 1 },
  // ...
]
const AbsencesEncodage = () => {
  const [cookies] = useCookies(['id', 'token', 'cours_fav'])
  const [jeunes, setJeunes] = useState([])
  const [date, setDate] = useState(null)
  const [feries, setFeries] = useState([])
  const [vacances, setVacances] = useState([])
  const [loading, setLoading] = useState(true)
  const [absencesData, setAbsencesData] = useState([])
  const [absencesTypes, setAbsencesTypes] = useState({})
  const [columns, setColumns] = useState([
    {
      title: 'Jeune',
      dataIndex: 'nom',
      key: 'nom',
    },
  ])
  const [rows, setRows] = useState([])

  const fetchAbsencesTypes = async () => {
    try {
      const response = await api(`absencetype/all`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setAbsencesTypes(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des absences types:', error)
    }
  }
  const fetchJeunes = async () => {
    try {
      const response = await apiPost(
        `jeune/all/absences`,
        {
          id: cookies['id'],
          token: cookies['token'],
        },
        { date: date }
      )
      setJeunes(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des absences types:', error)
    }
  }
  const fetchAbsencesData = async () => {
    try {
      const response = await api(`absence/find/${date}`, {
        id: cookies['id'],
        token: cookies['token'],
      })

      // Parse chaque champ "code" de la réponse
      const parsedData = response.data.map((absence) => ({
        ...absence,
        code: JSON.parse(absence.code),
      }))

      setAbsencesData(parsedData)
    } catch (error) {
      console.error('Erreur lors de la récupération des absences types:', error)
    }
  }
  const isDateAvailable = (currentDate) => {
    if (!feries.length || !vacances.length) return false // S’assure que les données sont chargées
    const dayOfWeek = dayjs(currentDate).day()
    const formattedDate = dayjs(currentDate).format('YYYY-MM-DD')
    const weekNumber = dayjs(currentDate).isoWeek()

    if (dayOfWeek === 0 || dayOfWeek === 6) return false // Weekends
    if (feries.includes(formattedDate)) return false // Fériés
    if (vacances.some((v) => v.numero === weekNumber)) {
      // Semaines de vacances
      console.log('Semaine de vacances trouvée:', weekNumber)
      return false
    }
    return true
  }

  const getNextAvailableDate = (startDate) => {
    let nextDate = dayjs(startDate, 'YYYY-MM-DD').add(1, 'day')
    while (!isDateAvailable(nextDate.format('YYYY-MM-DD'))) {
      nextDate = nextDate.add(1, 'day')
    }
    return nextDate.format('YYYY-MM-DD')
  }

  const getPreviousAvailableDate = (startDate) => {
    let prevDate = dayjs(startDate, 'YYYY-MM-DD').subtract(1, 'day')
    while (!isDateAvailable(prevDate.format('YYYY-MM-DD'))) {
      prevDate = prevDate.subtract(1, 'day')
    }
    return prevDate.format('YYYY-MM-DD')
  }

  const fetchConges = async () => {
    try {
      const response = await api('dates/conges', {
        id: cookies['id'],
        token: cookies['token'],
      })
      setVacances(response.data.vacances)
      setFeries(response.data.feries)
      setLoading(false) // Indique que le chargement est terminé
    } catch (error) {
      console.error('Erreur lors de la récupération des congés :', error)
    }
  }
  const disableDates = (date) => {
    const dayOfWeek = dayjs(date).day()
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    const weekNumber = dayjs(date).isoWeek()

    // Désactiver les samedis et dimanches
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return true
    }

    // Désactiver les jours fériés
    if (feries.includes(formattedDate)) {
      return true
    }

    // Désactiver les jours compris dans les semaines de vacances
    if (vacances.some((v) => v.numero === weekNumber)) {
      return true
    }

    return false
  }

  const saveCode = async (code) => {
    try {
      toast.dismiss()
      await toast.promise(
        // La promesse envoyée à l'API
        apiPost(
          `absence/save/${date}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          code
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des absences:', error)
    } finally {
      setAbsencesData(code)
    }
  }

  useEffect(() => {
    if (!loading) {
      const today = dayjs().format('YYYY-MM-DD')
      setDate(isDateAvailable(today) ? today : getNextAvailableDate(today))
    }
  }, [loading])

  useEffect(() => {
    fetchJeunes()
    fetchAbsencesData()
  }, [date])

  useEffect(() => {
    const initialData = {}
    initialAbsences.forEach((absence) => {
      const { jeune_id, periode, type_id } = absence
      if (!initialData[jeune_id]) initialData[jeune_id] = {}
      if (!initialData[jeune_id][periode]) initialData[jeune_id][periode] = {}

      initialData[jeune_id][periode][type_id] = true
    })
    setAbsencesData(initialData)

    fetchConges()
    fetchAbsencesTypes()
  }, [])

  useEffect(() => {
    let periode = 0
    switch (dayjs(date).day()) {
      case 1:
        periode = periodesParJours.lundi
        break
      case 2:
        periode = periodesParJours.mardi
        break
      case 3:
        periode = periodesParJours.mercredi
        break
      case 4:
        periode = periodesParJours.jeudi
        break
      case 5:
        periode = periodesParJours.vendredi
        break
      default:
        periode = 0 // Aucun jour de cours pour samedi et dimanche
        break
    }

    setRows(
      jeunes.map((jeune) => {
        const rowData = {
          nom: jeune.nom + ' ' + jeune.prenom,
          key: jeune.id,
        }
        const jeuneAbsences = Array.isArray(absencesData)
          ? absencesData.find((absence) => absence.eleve_id === jeune.id)?.code || []
          : []
        // Remplir chaque cellule avec une checkbox pour chaque type d'absence et chaque période
        for (let i = 0; i < periode; i++) {
          absencesTypes.forEach((abs) => {
            const key = `${abs.id}_${i}`

            const isChecked = jeuneAbsences.some(
              (absenceCode) => absenceCode.periode === i && absenceCode.absId === abs.id && absenceCode.checked
            )
            rowData[key] = (
              <AbsencesCheckbox
                abs={abs.id}
                periode={i}
                jeune={jeune.id}
                code={absencesData}
                setCode={saveCode}
                date={date}
                periodesCount={periode}
              />
            )
          })
        }

        return rowData
      })
    )
  }, [jeunes, absencesTypes, date, absencesData])

  useEffect(() => {
    // Initialiser les colonnes de base
    let newColumns = [
      {
        title: 'Jeune',
        dataIndex: 'nom',
        key: 'nom',
        fixed: 'left',
      },
    ]

    // Déterminer le nombre de périodes selon le jour de la semaine
    let periode = 0
    switch (dayjs(date).day()) {
      case 1:
        periode = periodesParJours.lundi
        break
      case 2:
        periode = periodesParJours.mardi
        break
      case 3:
        periode = periodesParJours.mercredi
        break
      case 4:
        periode = periodesParJours.jeudi
        break
      case 5:
        periode = periodesParJours.vendredi
        break
      default:
        periode = 0 // Aucun jour de cours pour samedi et dimanche
        break
    }

    // Générer dynamiquement les colonnes pour chaque période et type d'absence
    for (let i = 0; i < periode; i++) {
      const periodeColumns = absencesTypes.map((abs) => ({
        title: abs.intitule,
        dataIndex: `${abs.id}_${i}`,
        key: `${abs.id}_${i}`,
        className: `periode_${i}`,
      }))

      // Ajouter les colonnes pour cette période au tableau des colonnes
      newColumns = [...newColumns, ...periodeColumns]
    }

    // Mettre à jour les colonnes une seule fois en dehors de la boucle
    setColumns(newColumns)
  }, [absencesTypes, date])

  return (
    <div className="AbsencesEncodage" style={{ padding: '20px 60px' }}>
      <Titre>Les absences</Titre>
      <div className="Fond">
        {loading ? (
          <Spin />
        ) : (
          <>
            <Flex justify="space-between">
              <LeftOutlined
                style={{ fontSize: 20, cursor: 'pointer' }}
                onClick={() => setDate(getPreviousAvailableDate(date))}
              />
              <ConfigProvider locale={locale}>
                <DatePicker
                  value={dayjs(date)}
                  format={dateFormat}
                  disabledDate={disableDates}
                  onChange={(e) => setDate(dayjs(e).format('YYYY-MM-DD'))}
                />
              </ConfigProvider>
              <RightOutlined
                style={{ fontSize: 20, cursor: 'pointer' }}
                onClick={() => setDate(getNextAvailableDate(date))}
              />
            </Flex>
            <Table
              dataSource={rows}
              columns={columns}
              pagination={false}
              scroll={{ x: true, y: 55 * 5 }}
              style={{ marginTop: 20 }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default AbsencesEncodage
