import React, { useEffect, useState } from 'react'
import Titre from './Titre.jsx'
import { api, apiDelete, apiPost, apiPut } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import {
  Button,
  Collapse,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Radio,
  Space,
  Table,
  Typography,
} from 'antd'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'

dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const ParametresDates = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'id',
    'token',
    'cours_fav',
  ])
  const [dates, setDates] = useState([])
  const [date, setDate] = useState({ nom: null, date: [] })
  const [openModalDates, setOpenModalDates] = useState(false)
  const [cours, setCours] = useState([])
  const [profs, setProfs] = useState([])
  const [rangeDate, setRangeDate] = useState('mono')
  const [loadingDates, setLoadingDates] = useState(false)

  const columnsDates = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => {
        if (record.date.length > 1) {
          return `du ${dayjs(record.date[0]).format(dateFormat)} au ${dayjs(
            record.date[1]
          ).format(dateFormat)}`
        } else {
          return `le ${dayjs(record.date[0]).format(dateFormat)}`
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editDate(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => deleteDate(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]

  const fetchDates = async () => {
    try {
      const response = await api(`dates`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      const simplifiedArray = response.data.map((item) => {
        return {
          nom: item.datas.nom,
          date: item.datas.date,
          id: item.id,
        }
      })
      setDates(simplifiedArray)
    } catch (error) {
      console.error('Erreur lors de la récupération des dates:', error)
    }
  }

  const saveDate = async () => {
    if (date.id === 'new') {
      try {
        await toast.promise(
          apiPost(
            'dates/add',
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            { datas: date }
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalDates(false)
        fetchDates()
      }
    } else {
      try {
        await toast.promise(
          apiPut(
            `dates/update/${date.id}`,
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            { datas: date }
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalDates(false)
        fetchDates()
      }
    }
  }
  const editDate = (date) => {
    setDate(date)
    setOpenModalDates(true)
    if (date.date.length > 1) {
      setRangeDate('multi')
    } else {
      setRangeDate('mono')
    }
  }
  const deleteDate = async (dateId) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        apiDelete(
          `dates/delete/${dateId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { id: dateId }
        ),
        {
          pending: 'Suppression en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${
                data?.response?.data?.message || 'Veuillez réessayer.'
              }`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la suppression de la date: ', error)
    } finally {
      fetchDates()
    }
  }

  useEffect(() => {
    fetchDates()
  }, [])

  return (
    <Flex vertical>
      <Typography.Title
        style={{
          color: '#6a778e',
        }}
        level={3}
      >
        Les dates
      </Typography.Title>
      <div>
        <Button
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={() => {
            setDate({
              nom: null,
              date: [null, null],
              id: 'new',
            })
            setOpenModalDates(true)
          }}
        >
          Ajouter une date
        </Button>
      </div>
      <Modal
        open={openModalDates}
        destroyOnClose={true}
        onOk={saveDate}
        onCancel={() => setOpenModalDates(false)}
        width={600}
      >
        <Typography.Title
          style={{
            color: '#6a778e',
            width: '100%',
          }}
          level={3}
        >
          Ajouter une date
        </Typography.Title>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Radio.Group
            block
            options={[
              { label: 'Date unique', value: 'mono' },
              { label: 'Intervalle de dates', value: 'multi' },
            ]}
            defaultValue={rangeDate}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              setRangeDate(e.target.value)
            }}
          />
          <Typography.Title
            style={{
              color: '#6a778e',
            }}
            level={5}
          >
            Le nom
          </Typography.Title>
          <Input
            value={date.nom ? date.nom : null}
            placeholder="vacances de pâques, fête du travail..."
            style={{ width: '100%' }}
            onChange={(e) =>
              setDate({
                ...date,
                nom: e.target.value,
              })
            }
          />
          <Flex vertical>
            {rangeDate === 'mono' ? (
              <>
                <Typography.Title
                  style={{
                    color: '#6a778e',
                  }}
                  level={5}
                >
                  La date
                </Typography.Title>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    value={date.date[0] === null ? '' : dayjs(date.date[0])}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      setDate({
                        ...date,
                        date: [dayjs(e).format('YYYY-MM-DD')],
                      })
                    }
                  />
                </ConfigProvider>
              </>
            ) : (
              <>
                <Typography.Title
                  style={{
                    color: '#6a778e',
                  }}
                  level={5}
                >
                  La date de début
                </Typography.Title>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    value={date.date[0] === null ? '' : dayjs(date.date[0])}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      setDate({
                        ...date,
                        date: [dayjs(e).format('YYYY-MM-DD'), date.date[1]],
                      })
                    }
                  />
                  <Typography.Title
                    style={{
                      color: '#6a778e',
                    }}
                    level={5}
                  >
                    La date de fin
                  </Typography.Title>
                  <DatePicker
                    value={date.date[1] === null ? '' : dayjs(date.date[1])}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      setDate({
                        ...date,
                        date: [date.date[0], dayjs(e).format('YYYY-MM-DD')],
                      })
                    }
                  />
                </ConfigProvider>
              </>
            )}
          </Flex>
        </Space>
      </Modal>
      <Table bordered columns={columnsDates} dataSource={dates} />
    </Flex>
  )
}

export default ParametresDates
