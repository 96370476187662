import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import {
  Button,
  Checkbox,
  Collapse,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Typography,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { api, apiEcole, apiPost } from '../functions/api'
import { useCookies } from 'react-cookie'
import Titre from '../components/Titre'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
import { addWeeks, addDays, format, isSameWeek } from 'date-fns'

dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const Jeune = () => {
  const { id, page } = useParams()
  const [cookies] = useCookies(['id', 'token', 'cours_fav'])
  const [keyPage, setKeyPage] = useState(null)
  const [jeune, setJeune] = useState([])
  const [tuteurEdit, setTuteurEdit] = useState([])
  const [parcoursEdit, setParcoursEdit] = useState([])
  const [eoContactsEdit, setEoContactsEdit] = useState([])
  const [openModalTuteur, setOpenModalTuteur] = useState(false)
  const [openModalParcours, setOpenModalParcours] = useState(false)
  const [openModalEoContacts, setOpenModalEoContacts] = useState(false)
  const initialJeune = useRef(jeune)
  const [ecolesSearch, setEcolesSearch] = useState([])
  const [ecoleOrigineSearch, setEcoleOrigineSearch] = useState([])
  const [fetchingEcoles, setFetchingEcoles] = useState(false)
  const [titulaires, setTitulaires] = useState([])
  const [openModalBilan, setOpenModalBilan] = useState(false)
  const [bilanEdit, setBilanEdit] = useState([])
  const [
    openModalPriseEnChargeExterieure,
    setOpenModalPriseEnChargeExterieure,
  ] = useState(false)
  const [priseEnChargeExterieureEdit, setPriseEnChargeExterieureEdit] =
    useState([])
  const [openModalObjectif, setOpenModalObjectif] = useState(false)
  const [objectifEdit, setObjectifEdit] = useState(false)
  const [loading, setLoading] = useState(true) // État de chargement

  const columnsTuteur = [
    {
      title: 'Rôle',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Prénom',
      dataIndex: 'prenom',
      key: 'prenom',
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Adresse email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Numéro de téléphone',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editTuteur(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => deleteTuteur(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsBilan = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => dayjs(record.date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editBilan(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => deleteBilan(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsPriseEnChargeExterieure = [
    {
      title: 'Fonction',
      dataIndex: 'fonction',
      key: 'fonction',
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editPriseEnChargeExterieure(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deletePriseEnChargeExterieure(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsObjectifs = [
    {
      title: 'Souhait',
      dataIndex: 'objectif',
      key: 'objectif',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editObjetctif(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deleteObjectif(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsParcours = [
    {
      title: 'Niveau',
      dataIndex: 'niveau',
      key: 'niveau',
    },
    {
      title: 'Année',
      dataIndex: 'annee',
      key: 'annee',
    },
    {
      title: 'Ecole',
      dataIndex: 'ecole',
      key: 'ecole',
    },
    {
      title: 'Période',
      dataIndex: 'periode',
      key: 'periode',
      sorter: (a, b) => a.periode.localeCompare(b.periode), // Tri alphabétique
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editParcours(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deleteParcours(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const columnsEoContacts = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Nom et prenom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Téléphone',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'mail',
      key: 'mail',
    },
    {
      title: 'Autre',
      dataIndex: 'autre',
      key: 'autre',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => editEoContacts(record)}
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => deleteEoContacts(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]
  const fetchJeune = async () => {
    try {
      const response = await api(`jeune/find/${id}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      let jeuneData = response.data

      // Si tuteur est une chaîne JSON, le convertir en tableau
      if (typeof jeuneData.tuteur === 'string') {
        jeuneData.tuteur = JSON.parse(jeuneData.tuteur)
      }
      if (typeof jeuneData.parcours_scolaire === 'string') {
        jeuneData.parcours_scolaire = JSON.parse(jeuneData.parcours_scolaire)
      }
      if (typeof jeuneData.premiere_langue === 'string') {
        jeuneData.premiere_langue = JSON.parse(jeuneData.premiere_langue)
      }
      if (typeof jeuneData.eo_contacts === 'string') {
        jeuneData.eo_contacts = JSON.parse(jeuneData.eo_contacts)
      }
      if (typeof jeuneData.bilans === 'string') {
        jeuneData.bilans = JSON.parse(jeuneData.bilans)
      }
      if (typeof jeuneData.objectifs === 'string') {
        jeuneData.objectifs = JSON.parse(jeuneData.objectifs)
      }
      if (typeof jeuneData.prise_en_charge_exterieure === 'string') {
        jeuneData.prise_en_charge_exterieure = JSON.parse(
          jeuneData.prise_en_charge_exterieure
        )
      }
      setJeune(jeuneData)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    } finally {
      setLoading(false) // Changer l'état de chargement à false une fois les données récupérées
    }
  }
  const fetchTitulaires = async () => {
    try {
      const response = await apiPost(`prof/getTitulaires`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setTitulaires(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }
  const fetchEcoles = debounce(async (value) => {
    if (!value) {
      setEcolesSearch([])
      return
    }
    setFetchingEcoles(true)
    try {
      const response = await apiEcole(parcoursEdit.niveau, value)
      const result = await response.json()
      const newOptions = result.results.map((record) => ({
        value:
          record.nom_de_l_etablissement +
          ' (' +
          record.commune_de_l_etablissement +
          ')',
        label:
          record.nom_de_l_etablissement +
          ' (' +
          record.commune_de_l_etablissement +
          ')',
      }))
      setEcolesSearch(newOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }

    setFetchingEcoles(false)
  }, 800) // debounce pour limiter les appels API
  const fetchEcoleOrigine = debounce(async (value) => {
    if (!value) {
      setEcoleOrigineSearch([])
      return
    }
    setFetchingEcoles(true)
    try {
      const response = await apiEcole('Secondaire', value)
      const result = await response.json()
      const newOptions = result.results.map((record) => ({
        value:
          record.nom_de_l_etablissement +
          ' (' +
          record.commune_de_l_etablissement +
          ')',
        label:
          record.nom_de_l_etablissement +
          ' (' +
          record.commune_de_l_etablissement +
          ')',
      }))
      setEcoleOrigineSearch(newOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    }

    setFetchingEcoles(false)
  }, 800) // debounce pour limiter les appels API
  const saveTuteur = () => {
    let updatedJeune
    if (tuteurEdit.id === 'new') {
      const leTuteur = tuteurEdit
      leTuteur.id = Array.isArray(jeune.tuteur) ? jeune.tuteur.length + 1 : 1
      updatedJeune = {
        ...jeune,
        tuteur: [...(jeune.tuteur || []), leTuteur], // Ajoute tuteurEdit à jeune.tuteur
      }
    } else {
      updatedJeune = {
        ...jeune,
        tuteur: jeune.tuteur.map((tuteur) =>
          tuteur.id === tuteurEdit.id ? tuteurEdit : tuteur
        ),
      }
    }

    // Mets à jour le state avec le nouvel objet
    setJeune(updatedJeune)

    // Utilise directement l'objet mis à jour

    setOpenModalTuteur(false)

    saveJeune(updatedJeune)
  }
  const deleteTuteur = (tuteurId) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedTuteurs = jeune.tuteur.filter(
      (tuteur) => tuteur.id !== tuteurId
    )

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedTuteurs = updatedTuteurs.map((tuteur, index) => ({
      ...tuteur,
      id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
    }))

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      tuteur: reindexedTuteurs,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }
  const editTuteur = (tuteur) => {
    setTuteurEdit(tuteur)
    setOpenModalTuteur(true)
  }

  const saveParcours = () => {
    let updatedParcours

    if (parcoursEdit.id === 'new') {
      const leParcours = { ...parcoursEdit }
      leParcours.id = Array.isArray(jeune.parcours_scolaire)
        ? jeune.parcours_scolaire.length + 1
        : 1

      // Ajoute le nouveau parcours
      updatedParcours = {
        ...jeune,
        parcours_scolaire: [...(jeune.parcours_scolaire || []), leParcours],
      }
    } else {
      // Met à jour un parcours existant
      updatedParcours = {
        ...jeune,
        parcours_scolaire: jeune.parcours_scolaire.map((par) =>
          par.id === parcoursEdit.id ? parcoursEdit : par
        ),
      }
    }

    // Trie parcours_scolaire par ordre alphabétique de la période
    const sortedParcoursScolaire = sortByPeriode(
      updatedParcours.parcours_scolaire
    )

    // Mets à jour le state avec le tableau trié
    setJeune({
      ...updatedParcours,
      parcours_scolaire: sortedParcoursScolaire,
    })

    // Ferme le modal et sauvegarde les données
    setOpenModalParcours(false)
    saveJeune({
      ...updatedParcours,
      parcours_scolaire: sortedParcoursScolaire,
    })
  }
  const editParcours = (parcours) => {
    setParcoursEdit(parcours)
    setOpenModalParcours(true)
  }
  const deleteParcours = (parcoursId) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedParcours = jeune.parcours_scolaire.filter(
      (parcours) => parcours.id !== parcoursId
    )

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedParcours = updatedParcours.map((par, index) => ({
      ...par,
      id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
    }))

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      parcours_scolaire: reindexedParcours,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }
  const saveEoContacts = () => {
    let updatedEoContacts

    if (eoContactsEdit.id === 'new') {
      const leContact = { ...eoContactsEdit }
      leContact.id = Array.isArray(jeune.eo_contacts)
        ? jeune.eo_contacts.length + 1
        : 1

      // Ajoute le nouveau parcours
      updatedEoContacts = {
        ...jeune,
        eo_contacts: [...(jeune.eo_contacts || []), leContact],
      }
    } else {
      // Met à jour un parcours existant
      updatedEoContacts = {
        ...jeune,
        eo_contacts: jeune.eo_contacts.map((contact) =>
          contact.id === eoContactsEdit.id ? eoContactsEdit : contact
        ),
      }
    }

    // Trie parcours_scolaire par ordre alphabétique de la période

    // Mets à jour le state avec le tableau trié
    setJeune(updatedEoContacts)

    // Ferme le modal et sauvegarde les données
    setOpenModalEoContacts(false)
    saveJeune(updatedEoContacts)
  }
  const editEoContacts = (contact) => {
    setEoContactsEdit(contact)
    setOpenModalEoContacts(true)
  }
  const deleteEoContacts = (contactId) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedEoContacts = jeune.eo_contacts.filter(
      (contact) => contact.id !== contactId
    )

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedEoContacts = updatedEoContacts.map((par, index) => ({
      ...par,
      id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
    }))

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      eo_contacts: reindexedEoContacts,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }
  const saveBilan = async (bilanParams) => {
    let updatedBilan
    const leBilan = bilanParams ? bilanParams : { ...bilanEdit }
    if (leBilan.id === 'new') {
      try {
        const response = await apiPost(`bilans/create`, {
          id: cookies['id'],
          token: cookies['token'],
        })
        const bilan_id = response.data.id

        leBilan.id = Array.isArray(jeune.bilans) ? jeune.bilans.length + 1 : 1
        leBilan.bilan_id = bilan_id
        // Ajoute le nouveau parcours
        updatedBilan = {
          ...jeune,
          bilans: [...(jeune.bilans || []), leBilan],
        }
      } catch (error) {
        console.error("Erreur lors de l'ajout du bilan", error)
      }
    } else {
      // Met à jour un parcours existant
      updatedBilan = {
        ...jeune,
        bilans: jeune.bilans.map((bilan) =>
          bilan.id === leBilan.id ? leBilan : bilan
        ),
      }
    }

    // Trier bilan par date
    const sortedBilan = sortByDate(updatedBilan.bilans)
    updatedBilan = { ...jeune, bilans: sortedBilan }
    // Mets à jour le state avec le tableau trié
    setJeune(updatedBilan)

    // Ferme le modal et sauvegarde les données
    setOpenModalBilan(false)
    saveJeune(updatedBilan)
  }
  const editBilan = (bilan) => {
    setBilanEdit(bilan)
    setOpenModalBilan(true)
  }
  const deleteBilan = (bilanId) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedBilan = jeune.bilans.filter((bilan) => bilan.id !== bilanId)

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedBilan = updatedBilan.map((bilan, index) => ({
      ...bilan,
      id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
    }))

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      bilans: reindexedBilan,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }
  const saveObjectif = async () => {
    let updatedObjectifs

    if (objectifEdit.id === 'new') {
      const lObjectif = { ...objectifEdit }

      lObjectif.id = Array.isArray(jeune.objectifs)
        ? jeune.objectifs.length + 1
        : 1

      // Ajoute le nouveau parcours
      updatedObjectifs = {
        ...jeune,
        objectifs: [...(jeune.objectifs || []), lObjectif],
      }
    } else {
      // Met à jour un parcours existant
      updatedObjectifs = {
        ...jeune,
        objectifs: jeune.objectifs.map((objectif) =>
          objectif.id === objectifEdit.id ? objectifEdit : objectif
        ),
      }
    }

    // Trie parcours_scolaire par ordre alphabétique de la période

    // Mets à jour le state avec le tableau trié
    setJeune(updatedObjectifs)

    // Ferme le modal et sauvegarde les données
    setOpenModalObjectif(false)
    saveJeune(updatedObjectifs)
  }
  const editObjetctif = (objectif) => {
    setObjectifEdit(objectif)
    setOpenModalObjectif(true)
  }
  const deleteObjectif = (objectifId) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedObjectifs = jeune.objectifs.filter(
      (obj) => obj.id !== objectifId
    )

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedObjectifs = updatedObjectifs.map((obj, index) => ({
      ...obj,
      id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
    }))

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      objectifs: reindexedObjectifs,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }

  const savePriseEnChargeExterieure = async () => {
    let updatedPriseEnChargeExterieure

    if (priseEnChargeExterieureEdit.id === 'new') {
      const laPriseEnChargeExterieure = { ...priseEnChargeExterieureEdit }

      laPriseEnChargeExterieure.id = Array.isArray(
        jeune.prise_en_charge_exterieure
      )
        ? jeune.bilans.length + 1
        : 1

      // Ajoute le nouveau parcours
      updatedPriseEnChargeExterieure = {
        ...jeune,
        prise_en_charge_exterieure: [
          ...(jeune.prise_en_charge_exterieure || []),
          laPriseEnChargeExterieure,
        ],
      }
    } else {
      // Met à jour un parcours existant
      updatedPriseEnChargeExterieure = {
        ...jeune,
        prise_en_charge_exterieure: jeune.prise_en_charge_exterieure.map(
          (prise) =>
            prise.id === priseEnChargeExterieureEdit.id
              ? priseEnChargeExterieureEdit
              : prise
        ),
      }
    }

    // Trie parcours_scolaire par ordre alphabétique de la période

    // Mets à jour le state avec le tableau trié
    setJeune(updatedPriseEnChargeExterieure)

    // Ferme le modal et sauvegarde les données
    setOpenModalPriseEnChargeExterieure(false)
    saveJeune(updatedPriseEnChargeExterieure)
  }
  const editPriseEnChargeExterieure = (prise) => {
    setPriseEnChargeExterieureEdit(prise)
    setOpenModalPriseEnChargeExterieure(true)
  }
  const deletePriseEnChargeExterieure = (priseEnChargeExterieure) => {
    // Supprimer le tuteur avec l'id fourni
    const updatedPriseEnChargeExterieure =
      jeune.prise_en_charge_exterieure.filter(
        (prise) => prise.id !== priseEnChargeExterieure
      )

    // Réassigner de nouveaux IDs uniques et séquentiels
    const reindexedPriseEnChargeExterieure = updatedPriseEnChargeExterieure.map(
      (prise, index) => ({
        ...prise,
        id: index + 1, // Réattribue l'id de façon séquentielle à partir de 1
      })
    )

    // Mettre à jour l'objet jeune avec les tuteurs réindexés
    const jeuneUpdate = {
      ...jeune,
      prise_en_charge_exterieure: reindexedPriseEnChargeExterieure,
    }

    // Mettre à jour le state avec l'objet jeune modifié
    setJeune(jeuneUpdate)

    // Sauvegarder en base de données
    saveJeune(jeuneUpdate)
  }
  const debouncedSaveJeune = debounce((updatedJeune) => {
    saveJeune(updatedJeune)
    setJeune(updatedJeune)
  }, 800)
  const saveJeune = async (jeuneToUpdate) => {
    if (
      JSON.stringify(initialJeune.current) !== JSON.stringify(jeuneToUpdate)
    ) {
      // Envoie la requête à l'API uniquement si 'jeune' a changé
      try {
        await toast.promise(
          // La promesse envoyée à l'API
          apiPost(
            `jeune/update/${id}`,
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            jeuneToUpdate
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
        initialJeune.current = jeuneToUpdate
      } catch (error) {
        console.error('Erreur lors de la récupération des profs:', error)
      } finally {
        fetchTitulaires()
      }
    }
  }
  const sortByPeriode = (arr) => {
    return arr.sort((a, b) => {
      const periodeA = a.periode || ''
      const periodeB = b.periode || ''
      return periodeA.localeCompare(periodeB)
    })
  }
  const sortByDate = (data) => {
    // Trier par date de la plus ancienne à la plus récente
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date))

    // Réindexer l'id sans toucher à bilan_id
    return sortedData.map((item, index) => ({
      ...item,
      id: index + 1, // Réindexation de l'id, commençant à 1
    }))
  }
  const editPremiereLangue = (checkedValues) => {
    const leJeune = { ...jeune, premiere_langue: checkedValues }
    saveJeune(leJeune)
    setJeune(leJeune)
  }
  const editTitulaires = (titulaire) => {
    const leJeune = { ...jeune, referent_id: titulaire }
    saveJeune(leJeune)
    setJeune(leJeune)
  }
  const decodeHtmlEntities = (str) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = str
    return txt.value
  }
  const navigate = useNavigate()

  const handleCollapseChange = (key) => {
    key = key[0]
    const currentUrl = window.location.pathname // Ex: /Jeune/7/1
    const currentKey = currentUrl.split('/')[3]

    if (parseInt(currentKey) !== parseInt(key)) {
      const newUrl = currentUrl.split('/').slice(0, -1).join('/') + '/' + key // Remplace le dernier paramètre avec la clé
      navigate(newUrl) // Met à jour l'URL sans recharger la page
    }
  }
  const calculerDateParSemaine = (
    dateInitiale,
    nombreDeSemaines,
    jourSemaine,
    DatesVacances,
    datesFeries
  ) => {
    let dateCourante = new Date(dateInitiale) // Date initiale
    let semainesAjoutees = 0

    // Fonction pour vérifier si une semaine est dans les vacances
    const estSemaineDeVacances = (date, DatesVacances) => {
      const numeroSemaine = getNumeroSemaine(date)
      const annee = date.getFullYear()
      return DatesVacances.some(
        (vac) => vac.numero === numeroSemaine && vac.annee === annee
      )
    }

    // Fonction pour obtenir le numéro de la semaine
    const getNumeroSemaine = (date) => {
      const startOfYear = new Date(date.getFullYear(), 0, 1)
      const diff =
        date -
        startOfYear +
        (startOfYear.getDay() ? (7 - startOfYear.getDay()) * 86400000 : 0)
      return Math.ceil(diff / (7 * 86400000))
    }

    // Boucle pour ajouter les semaines une par une en évitant les vacances
    while (semainesAjoutees < nombreDeSemaines) {
      dateCourante = addWeeks(dateCourante, 1)
      if (!estSemaineDeVacances(dateCourante, DatesVacances)) {
        semainesAjoutees++
      }
    }

    // Calculer la date du jour souhaité dans la semaine trouvée
    const numeroJourSemaine = jourSemaine % 7 // Jour de la semaine (dimanche = 0)
    let dateFinale = addDays(
      dateCourante,
      numeroJourSemaine - dateCourante.getDay()
    )

    // Fonction pour vérifier si une date est un jour férié
    const estJourFerie = (date) => {
      return datesFeries.some((ferie) => format(date, 'yyyy-MM-dd') === ferie)
    }

    // Si la date finale est un jour férié, chercher une autre date
    if (estJourFerie(dateFinale)) {
      for (let i = 1; i <= 6; i++) {
        const nouvelleDate = addDays(dateFinale, -i)
        if (!estJourFerie(nouvelleDate)) {
          dateFinale = nouvelleDate
          break
        }
      }
    }

    // Retourner la date finale formatée
    return format(dateFinale, 'yyyy-MM-dd')
  }
  const calculerDate = async (
    nombreDeSemaines,
    jourSemaine,
    demande,
    dateInit = null
  ) => {
    if (jeune.date_entree) {
      const dateInitiale = jeune.date_entree
      try {
        const response = await api('dates/conges', {
          id: cookies['id'],
          token: cookies['token'],
        })

        const vacances = response.data.vacances
        const feries = response.data.feries
        if (demande === 'dateSortie') {
          const dateTerminal = calculerDateParSemaine(
            dateInitiale,
            nombreDeSemaines,
            jourSemaine,
            vacances,
            feries
          )
          console.log(dateTerminal)
          saveJeune({ ...jeune, date_sortie: dateTerminal })
          setJeune({ ...jeune, date_sortie: dateTerminal })
        }
        if (demande === 'bilan') {
          const dateBilan = calculerDateParSemaine(
            dateInit,
            nombreDeSemaines,
            jourSemaine,
            vacances,
            feries
          )
          setBilanEdit({ ...bilanEdit, date: dateBilan + ' 10:00' })
        }
      } catch (error) {
        console.error('erreur de la récupération des dates:', error)
      }
    } else {
      toast.error("Une date d'entrée valide doit être fournie")
    }
  }
  const definirDateBilan = () => {
    // 1. Compter le nombre de bilans
    const nombreBilans = jeune.bilans.length

    // 2. Calculer le nombre de semaines
    let semaines
    if (nombreBilans % 4 === 0) {
      semaines = 2
    } else if (nombreBilans % 4 === 1 || nombreBilans % 4 === 2) {
      semaines = 5
    } else if (nombreBilans % 4 === 3) {
      semaines = 6
    }

    // 3. Récupérer la date du dernier bilan
    const dernierBilan = jeune.bilans[jeune.bilans.length - 1]
    const dateDernierBilan = dernierBilan
      ? dayjs(dernierBilan.date).format('YYYY-MM-DD')
      : jeune.date_entree
    // 4. Appeler la fonction calculerDate avec les paramètres

    calculerDate(semaines, 2, 'bilan', dateDernierBilan)
  }

  useEffect(() => {
    fetchJeune()
  }, [id])
  useEffect(() => {
    const interval = setInterval(() => {
      saveJeune(jeune)
    }, 20000)

    return () => clearInterval(interval)
  }, [jeune])
  useEffect(() => {
    setKeyPage(page)
    fetchTitulaires()
  }, [])
  useEffect(() => {
    setKeyPage(page)
  }, [page])
  if (loading) {
    return (
      <Flex
        style={{ width: '100%', height: '100vh' }}
        justify="center"
        align="center"
      >
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </Flex>
    )
    // Afficher un message de chargement pendant le fetch
  }
  return (
    <div className="Jeune fondPage">
      <Titre>
        {jeune.prenom ? jeune.prenom : null} {jeune.nom ? jeune.nom : null}
      </Titre>
      <div className="page">
        <Collapse
          accordion
          onChange={(e) => handleCollapseChange(e)}
          items={[
            {
              key: '1',
              label: 'Les informations du jeunes',
              children: (
                <div>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 20 }}
                  >
                    <Space style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                          width: '100%',
                        }}
                        level={3}
                      >
                        Le jeune
                      </Typography.Title>
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le prénom
                      </Typography.Title>
                      <Input
                        value={jeune.prenom}
                        onChange={(e) =>
                          setJeune({ ...jeune, prenom: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le nom de famille
                      </Typography.Title>
                      <Input
                        value={jeune.nom}
                        onChange={(e) =>
                          setJeune({ ...jeune, nom: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La date de Naissance
                      </Typography.Title>
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          value={
                            jeune.date_naissance === null
                              ? ''
                              : dayjs(jeune.date_naissance)
                          }
                          format={dateFormat}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setJeune({
                              ...jeune,
                              date_naissance: dayjs(e).format('YYYY-MM-DD'),
                            })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </ConfigProvider>
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        NISS
                      </Typography.Title>
                      <ConfigProvider locale={locale}>
                        <Input
                          value={
                            jeune.niss
                              ? jeune.niss
                              : jeune.date_naissance
                              ? dayjs(jeune.date_naissance).format('YYMMDD')
                              : ''
                          }
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setJeune({ ...jeune, niss: e.target.value })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </ConfigProvider>
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 20 }}
                  >
                    <Space direction="vertical" style={{ width: '33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        L'adresse email
                      </Typography.Title>
                      <Space.Compact style={{ width: '100%' }}>
                        <Input
                          addonBefore={<MailOutlined />}
                          value={jeune.email}
                          onChange={(e) =>
                            setJeune({ ...jeune, email: e.target.value })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </Space.Compact>
                    </Space>
                    <Space direction="vertical" style={{ width: '33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le numero de téléphone
                      </Typography.Title>
                      <Space.Compact style={{ width: '100%' }}>
                        <Input
                          addonBefore={<PhoneOutlined />}
                          value={jeune.telephone}
                          onChange={(e) =>
                            setJeune({ ...jeune, telephone: e.target.value })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </Space.Compact>
                    </Space>
                    <Space direction="vertical" style={{ width: '33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        l'Autorisation de fumer
                      </Typography.Title>
                      <Space.Compact style={{ width: '100%' }}>
                        <Switch
                          checkedChildren="Oui"
                          unCheckedChildren="Non"
                          defaultChecked={jeune.smoke}
                          onChange={(checked) => {
                            const updatedJeune = {
                              ...jeune,
                              smoke: checked ? 1 : 0,
                            }
                            setJeune(updatedJeune)
                            saveJeune(updatedJeune)
                          }}
                        />
                      </Space.Compact>
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 20 }}
                  >
                    <Space style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                          width: '100%',
                        }}
                        level={3}
                      >
                        L'adresse
                      </Typography.Title>
                    </Space>
                  </Flex>

                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 0 }}
                  >
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La rue
                      </Typography.Title>
                      <Input
                        value={jeune.rue}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setJeune({ ...jeune, rue: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le numéro
                      </Typography.Title>
                      <Input
                        value={jeune.numero}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setJeune({ ...jeune, numero: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le code postal
                      </Typography.Title>
                      <Input
                        value={jeune.cp}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setJeune({ ...jeune, cp: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '24.25%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La ville
                      </Typography.Title>
                      <Input
                        value={jeune.ville}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setJeune({ ...jeune, ville: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                  </Flex>
                </div>
              ),
            },
            {
              key: '2',
              label: 'Les tuteurs',
              children: (
                <div>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setTuteurEdit({
                        role: '',
                        nom: '',
                        prenom: '',
                        email: '',
                        telephone: '',
                        adresse: '',
                        id: 'new',
                      })
                      setOpenModalTuteur(true)
                    }}
                  >
                    Ajouter un tuteur
                  </Button>
                  <Modal
                    open={openModalTuteur}
                    destroyOnClose={true}
                    onOk={saveTuteur}
                    onCancel={() => setOpenModalTuteur(false)}
                    width={1000}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Information du tuteur
                    </Typography.Title>
                    <Flex justify="space-between">
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Rôle
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.role}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              role: e.target.value,
                            })
                          }
                        />
                      </Flex>
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Nom
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.nom}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              nom: e.target.value,
                            })
                          }
                        />
                      </Flex>
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Prénom
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.prenom}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              prenom: e.target.value,
                            })
                          }
                        />
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Téléphone
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.telephone}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              telephone: e.target.value,
                            })
                          }
                        />
                      </Flex>
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Email
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.email}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              email: e.target.value,
                            })
                          }
                        />
                      </Flex>
                      <Flex vertical style={{ width: '33%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Adresse
                        </Typography.Title>
                        <Input
                          value={tuteurEdit.adresse}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setTuteurEdit({
                              ...tuteurEdit,
                              adresse: e.target.value,
                            })
                          }
                        />
                      </Flex>
                    </Flex>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsTuteur}
                    dataSource={jeune.tuteur ? jeune.tuteur : null}
                  />
                </div>
              ),
            },
            {
              key: '3',
              label: 'Le volet scolaire',
              children: (
                <div>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 20 }}
                  >
                    <Space style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                          width: '100%',
                        }}
                        level={3}
                      >
                        L'école d'origine
                      </Typography.Title>
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {jeune.ecole_origine ? (
                        <Select
                          showSearch
                          filterOption={false}
                          onSearch={fetchEcoleOrigine}
                          notFoundContent={<Spin size="small" />}
                          placeholder="Rechercher un établissement"
                          defaultValue={jeune.ecole_origine}
                          allowClear
                          options={ecoleOrigineSearch} // Utilisation de la props options
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            setJeune({ ...jeune, ecole_origine: e })
                            saveJeune(jeune)
                          }}
                        />
                      ) : null}
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '32.33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        L'année
                      </Typography.Title>
                      <Input
                        value={
                          jeune.annee_scolaire !== 0
                            ? jeune.annee_scolaire
                            : null
                        }
                        onChange={(e) =>
                          setJeune({ ...jeune, annee_scolaire: e.target.value })
                        }
                        onBlur={() => saveJeune(jeune)}
                        placeholder="6E, 2cA..."
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '32.33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        L'option scolaire
                      </Typography.Title>
                      <Input
                        value={jeune.option_scolaire}
                        onChange={(e) =>
                          setJeune({
                            ...jeune,
                            option_scolaire: e.target.value,
                          })
                        }
                        placeholder="Sciences fortes, Latin-grec, Science humaine..."
                        onBlur={() => saveJeune(jeune)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '32.33%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La première langue
                      </Typography.Title>

                      <Checkbox.Group
                        options={['Neerlandais', 'Anglais']}
                        onChange={editPremiereLangue}
                        defaultValue={jeune.premiere_langue}
                      />
                    </Space>
                  </Flex>

                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Les profs et personnes de contact de l'école d'origine
                      </Typography.Title>
                    </Space>
                  </Flex>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setEoContactsEdit({
                        id: 'new',
                        role: '',
                        nom: '',
                        telephone: '',
                        mail: '',
                        autre: '',
                      })
                      setOpenModalEoContacts(true)
                    }}
                  >
                    Ajouter une personne
                  </Button>
                  <Modal
                    open={openModalEoContacts}
                    destroyOnClose={true}
                    onOk={saveEoContacts}
                    onCancel={() => setOpenModalEoContacts(false)}
                    width={600}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Ajouter une personne de contact
                    </Typography.Title>
                    <Flex justify="space-between">
                      <Flex vertical style={{ width: '49%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Role
                        </Typography.Title>
                        <Input
                          defaultValue={eoContactsEdit.role}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setEoContactsEdit({
                              ...eoContactsEdit,
                              role: e.target.value,
                            })
                          }
                          placeholder="Educateur, prof de maths et titulaire..."
                        />
                      </Flex>
                      <Flex vertical style={{ width: '49%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Nom et prénom
                        </Typography.Title>
                        <Input
                          defaultValue={eoContactsEdit.nom}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setEoContactsEdit({
                              ...eoContactsEdit,
                              nom: e.target.value,
                            })
                          }
                          placeholder="Nom et prénom..."
                        />
                      </Flex>
                    </Flex>
                    <Flex justify="space-between">
                      <Flex vertical style={{ width: '49%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Téléphone
                        </Typography.Title>
                        <Input
                          defaultValue={eoContactsEdit.telephone}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setEoContactsEdit({
                              ...eoContactsEdit,
                              telephone: e.target.value,
                            })
                          }
                          placeholder="Numero de téléphone..."
                        />
                      </Flex>
                      <Flex vertical style={{ width: '49%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Email
                        </Typography.Title>
                        <Input
                          defaultValue={eoContactsEdit.mail}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setEoContactsEdit({
                              ...eoContactsEdit,
                              mail: e.target.value,
                            })
                          }
                          placeholder="Adresse email..."
                        />
                      </Flex>
                    </Flex>

                    <Flex vertical style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                          width: '100%',
                        }}
                        level={5}
                      >
                        Autre
                      </Typography.Title>
                      <Input
                        defaultValue={eoContactsEdit.autre}
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setEoContactsEdit({
                            ...eoContactsEdit,
                            autre: e.target.value,
                          })
                        }
                        placeholder="Autres informations utiles..."
                      />
                    </Flex>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsEoContacts}
                    dataSource={jeune.eo_contacts ? jeune.eo_contacts : null}
                  />
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 20 }}
                    vertical
                  >
                    <Space style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                          width: '100%',
                        }}
                        level={3}
                      >
                        Le parcours scolaire
                      </Typography.Title>
                    </Space>
                  </Flex>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setParcoursEdit({
                        id: 'new',
                        niveau: 'Maternelle',
                        annee: '',
                        ecole: '',
                        periode: '',
                        action: '',
                      })
                      setOpenModalParcours(true)
                    }}
                  >
                    Ajouter une étape
                  </Button>
                  <Modal
                    open={openModalParcours}
                    destroyOnClose={true}
                    onOk={saveParcours}
                    onCancel={() => setOpenModalParcours(false)}
                    width={600}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Ajouter une étape au parcours scolaire
                    </Typography.Title>
                    <Flex vertical>
                      <Flex vertical>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Niveau
                        </Typography.Title>
                        <Radio.Group
                          options={[
                            { label: 'Maternelle', value: 'Maternelle' },
                            { label: 'Primaire', value: 'Primaire' },
                            { label: 'Secondaire', value: 'Secondaire' },
                          ]}
                          onChange={(e) =>
                            setParcoursEdit({
                              ...parcoursEdit,
                              niveau: e.target.value,
                            })
                          }
                          defaultValue={
                            parcoursEdit.niveau
                              ? parcoursEdit.niveau
                              : 'Maternelle'
                          }
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Flex>
                      <Flex vertical style={{ width: '100%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Années scolaires
                        </Typography.Title>
                        <Input
                          defaultValue={parcoursEdit.annee}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setParcoursEdit({
                              ...parcoursEdit,
                              annee: e.target.value,
                            })
                          }
                          placeholder="exemples: de la 1ère à la 3ème science sociale ou 5ème maths6..."
                        />
                      </Flex>
                      <Flex vertical style={{ width: '100%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          Période
                        </Typography.Title>
                        <Input
                          defaultValue={parcoursEdit.periode}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setParcoursEdit({
                              ...parcoursEdit,
                              periode: e.target.value,
                            })
                          }
                          placeholder="exemples: 2019 ou 2014-2016..."
                        />
                      </Flex>
                      <Flex vertical style={{ width: '100%' }}>
                        <Typography.Title
                          style={{
                            color: '#6a778e',
                            width: '100%',
                          }}
                          level={5}
                        >
                          L'école
                        </Typography.Title>
                        <Select
                          showSearch
                          filterOption={false}
                          onSearch={fetchEcoles}
                          notFoundContent={
                            fetchEcoles ? <Spin size="small" /> : null
                          }
                          placeholder="Rechercher un établissement"
                          defaultValue={parcoursEdit.ecole}
                          allowClear
                          options={ecolesSearch} // Utilisation de la props options
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setParcoursEdit({
                              ...parcoursEdit,
                              ecole: e,
                            })
                          }
                        />
                      </Flex>
                    </Flex>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsParcours}
                    dataSource={
                      jeune.parcours_scolaire ? jeune.parcours_scolaire : null
                    }
                  />
                </div>
              ),
            },
            {
              key: '4',
              label: "La prise en charge à l'école Escale",
              children: (
                <div>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '49%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le référent pedagogique
                      </Typography.Title>
                      <Select
                        value={jeune.referent_id}
                        options={titulaires.map((item) => ({
                          value: item.id,
                          label: (
                            <span>
                              {item.nom} ({item.tutores.length} jeune
                              {item.tutores.length > 1 ? 's ' : ' '}
                              actuellement)
                            </span>
                          ),
                        }))}
                        placeholder="Selectionne un titulaire"
                        style={{ width: '100%' }}
                        onChange={(e) => editTitulaires(e)}
                      />
                    </Space>
                    <Space direction="vertical" style={{ width: '49%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La pédopsychiatre référente
                      </Typography.Title>
                      <Select
                        style={{ width: '100%' }}
                        options={[
                          { label: 'Stephanie Delmarque', value: 1 },
                          { label: 'Gwendoline Goossens', value: 2 },
                        ]}
                        value={jeune.pedopsy_id}
                        onChange={(e) => {
                          const updatedJeune = { ...jeune, pedopsy_id: e }
                          saveJeune(updatedJeune)
                          setJeune(updatedJeune)
                        }}
                      />
                    </Space>
                  </Flex>
                  <Flex
                    justify={'space-between'}
                    align={'center'}
                    style={{ marginTop: 10 }}
                  >
                    <Space direction="vertical" style={{ width: '49%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La date d'entrée
                      </Typography.Title>
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          value={
                            jeune.date_entree === null
                              ? ''
                              : dayjs(jeune.date_entree)
                          }
                          format={dateFormat}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setJeune({
                              ...jeune,
                              date_entree: dayjs(e).format('YYYY-MM-DD'),
                            })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </ConfigProvider>
                    </Space>
                    <Space direction="vertical" style={{ width: '49%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La date de sortie{' '}
                        <Button
                          size="small"
                          style={{ marginLeft: 10, fontSize: 10 }}
                          onClick={() => calculerDate(18, 5, 'dateSortie')}
                        >
                          calculer la date
                        </Button>
                      </Typography.Title>
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          value={
                            jeune.date_sortie === null
                              ? ''
                              : dayjs(jeune.date_sortie)
                          }
                          format={dateFormat}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setJeune({
                              ...jeune,
                              date_sortie: dayjs(e).format('YYYY-MM-DD'),
                            })
                          }
                          onBlur={() => saveJeune(jeune)}
                        />
                      </ConfigProvider>
                    </Space>
                  </Flex>
                  <Space
                    direction="vertical"
                    style={{ width: '100%', marginTop: 10 }}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                      }}
                      level={5}
                    >
                      La liste des bilans
                    </Typography.Title>
                  </Space>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setBilanEdit({
                        id: 'new',
                        date: null,
                        bilan_id: null,
                      })
                      setOpenModalBilan(true)
                    }}
                  >
                    Ajouter un bilan
                  </Button>
                  <Modal
                    open={openModalBilan}
                    destroyOnClose={true}
                    onOk={() => saveBilan(bilanEdit)}
                    onCancel={() => setOpenModalBilan(false)}
                    width={600}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Ajouter un bilan
                    </Typography.Title>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La date du bilan
                      </Typography.Title>
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          showTime
                          value={
                            bilanEdit.date === null ? '' : dayjs(bilanEdit.date)
                          }
                          format={dateTimeFormat}
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            setBilanEdit({
                              ...bilanEdit,
                              date: dayjs(e).format('YYYY-MM-DD HH:mm'),
                            })
                          }
                        />
                      </ConfigProvider>
                    </Space>
                    <Space
                      direction="vertical"
                      style={{ width: '100%', marginTop: 10 }}
                    >
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le prochain bilan théorique
                      </Typography.Title>
                    </Space>
                    <Button onClick={definirDateBilan}>
                      Bilan n°{jeune.bilans ? (jeune.bilans.length % 4) + 1 : 1}
                    </Button>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsBilan}
                    dataSource={jeune.bilans ? jeune.bilans : null}
                  />
                  <Space
                    direction="vertical"
                    style={{ width: '100%', marginTop: 10 }}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                      }}
                      level={5}
                    >
                      Les souhaits du jeune
                    </Typography.Title>
                  </Space>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setObjectifEdit({
                        id: 'new',
                        objectif: null,
                      })
                      setOpenModalObjectif(true)
                    }}
                  >
                    Ajouter un souhait
                  </Button>
                  <Modal
                    open={openModalObjectif}
                    destroyOnClose={true}
                    onOk={saveObjectif}
                    onCancel={() => setOpenModalObjectif(false)}
                    width={300}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Ajouter un souhait
                    </Typography.Title>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le souhait
                      </Typography.Title>

                      <Input
                        value={
                          objectifEdit.objectif === null
                            ? ''
                            : objectifEdit.objectif
                        }
                        placeholder="Le souhait du jeune"
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setObjectifEdit({
                            ...objectifEdit,
                            objectif: e.target.value,
                          })
                        }
                      />
                    </Space>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsObjectifs}
                    dataSource={jeune.objectifs ? jeune.objectifs : null}
                  />
                </div>
              ),
            },
            {
              key: '5',
              label: 'La prise en charge extérieure',
              children: (
                <div>
                  <Button
                    type="primary"
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      setPriseEnChargeExterieureEdit({
                        id: 'new',
                        fonction: '',
                        nom: '',
                      })
                      setOpenModalPriseEnChargeExterieure(true)
                    }}
                  >
                    Ajouter une prise en charge
                  </Button>
                  <Modal
                    open={openModalPriseEnChargeExterieure}
                    destroyOnClose={true}
                    onOk={savePriseEnChargeExterieure}
                    onCancel={() => setOpenModalPriseEnChargeExterieure(false)}
                    width={600}
                  >
                    <Typography.Title
                      style={{
                        color: '#6a778e',
                        width: '100%',
                      }}
                      level={3}
                    >
                      Ajouter une prise en charge extérieure
                    </Typography.Title>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        La fonction professionnelle
                      </Typography.Title>

                      <Input
                        value={
                          priseEnChargeExterieureEdit.fonction === null
                            ? ''
                            : priseEnChargeExterieureEdit.fonction
                        }
                        placeholder="Pédopsy, ergo, Kiné..."
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setPriseEnChargeExterieureEdit({
                            ...priseEnChargeExterieureEdit,
                            fonction: e.target.value,
                          })
                        }
                      />
                    </Space>
                    <Space
                      direction="vertical"
                      style={{ width: '100%', marginTop: 10 }}
                    >
                      <Typography.Title
                        style={{
                          color: '#6a778e',
                        }}
                        level={5}
                      >
                        Le nom
                      </Typography.Title>

                      <Input
                        value={
                          priseEnChargeExterieureEdit.nom === null
                            ? ''
                            : priseEnChargeExterieureEdit.nom
                        }
                        placeholder="Le nom du médecin"
                        style={{ width: '100%' }}
                        onChange={(e) =>
                          setPriseEnChargeExterieureEdit({
                            ...priseEnChargeExterieureEdit,
                            nom: e.target.value,
                          })
                        }
                      />
                    </Space>
                  </Modal>
                  <Table
                    bordered
                    columns={columnsPriseEnChargeExterieure}
                    dataSource={
                      jeune.prise_en_charge_exterieure
                        ? jeune.prise_en_charge_exterieure
                        : null
                    }
                  />
                  <Typography.Title
                    style={{
                      color: '#6a778e',
                      width: '100%',
                    }}
                    level={3}
                  >
                    Les antécédents médicaux
                  </Typography.Title>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'heading', // Titres
                          'fontSize', // Taille de texte
                          'bold', // Gras
                          'italic', // Italique
                          'underline', // Souligné
                          'strikethrough', // Barré

                          '|',
                          'bulletedList', // Liste non ordonnée (ul)
                          'numberedList', // Liste ordonnée (ol)
                          '|',
                          'alignment', // Alignement de texte
                          'link', // Liens
                          'imageUpload', // Image
                          'undo', // Annuler
                          'redo', // Répéter
                        ],
                      },
                      plugins: [
                        Bold,
                        Italic,
                        Underline,
                        Strikethrough,
                        Font,

                        Paragraph,
                        List,
                        Alignment,
                        Link,
                        Image,
                      ],
                      fontSize: {
                        options: ['tiny', 'small', 'default', 'big', 'huge'],
                      },
                      alignment: {
                        options: ['left', 'center', 'right', 'justify'],
                      },

                      initialData: jeune.antecedants,
                    }}
                    onChange={(e, editor) => {
                      const antecedants = editor.data.get()
                      const updatedJeune = {
                        ...jeune,
                        antecedants: antecedants,
                      }
                      debouncedSaveJeune(updatedJeune)
                    }}
                  />
                </div>
              ),
            },
            {
              key: '6',
              label: 'Autres informations',
              children: (
                <div>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: {
                        items: [
                          'heading', // Titres
                          'fontSize', // Taille de texte
                          'bold', // Gras
                          'italic', // Italique
                          'underline', // Souligné
                          'strikethrough', // Barré

                          '|',
                          'bulletedList', // Liste non ordonnée (ul)
                          'numberedList', // Liste ordonnée (ol)
                          '|',
                          'alignment', // Alignement de texte
                          'link', // Liens
                          'imageUpload', // Image
                          'undo', // Annuler
                          'redo', // Répéter
                        ],
                      },
                      plugins: [
                        Bold,
                        Italic,
                        Underline,
                        Strikethrough,
                        Font,

                        Paragraph,
                        List,
                        Alignment,
                        Link,
                        Image,
                      ],
                      fontSize: {
                        options: ['tiny', 'small', 'default', 'big', 'huge'],
                      },
                      alignment: {
                        options: ['left', 'center', 'right', 'justify'],
                      },

                      initialData: jeune.notes_supplementaires,
                    }}
                    onChange={(e, editor) => {
                      const notes_supplementaires = editor.data.get()
                      const updatedJeune = {
                        ...jeune,
                        notes_supplementaires: notes_supplementaires,
                      }
                      debouncedSaveJeune(updatedJeune)
                    }}
                  />
                </div>
              ),
            },
          ]}
          size="large"
          activeKey={keyPage}
        />
        <Flex justify="end">
          <Button
            type="primary"
            onClick={() => {
              if (
                JSON.stringify(initialJeune.current) === JSON.stringify(jeune)
              ) {
                toast.success('Données sauvegardées avec succès 👌')
              }
              saveJeune(jeune)
            }}
            icon={<SaveOutlined />}
          >
            Sauvegarder
          </Button>
        </Flex>
      </div>
    </div>
  )
}

export default Jeune
