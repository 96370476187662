import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import Home from '../pages/Home.jsx'
import CarnetLisaisonLecture from '../pages/CarnetDeLiaisonLecture.jsx'
import Parametres from '../pages/Parametres.jsx'
import AccesRefuse from '../components/AccesRefuse.jsx'
import CarnetDeLiaisonEncodage from '../pages/CarnetDeLiaisonEncodage.jsx'
import EditeurDeTexte from '../pages/EditeurDeTexte.jsx'
import Jeune from '../pages/Jeune.jsx'
import NavBarJeune from './NavBarJeune.jsx'
import AbsencesEncodage from '../pages/AbsencesEncodage.jsx'

const Main = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token'])
  return (
    <div className="Main">
      {cookies['id'] && cookies['token'] ? (
        <Routes>
          <Route path="/" element={<AbsencesEncodage />} />
          <Route path="/CarnetDeLiaisonLecture" element={<CarnetLisaisonLecture />} />
          <Route path="CarnetDeLiaisonEncodage" element={<CarnetDeLiaisonEncodage />} />
          <Route path="EditeurDeTexte" element={<EditeurDeTexte />} />
          <Route path="Jeune/:id/:page" element={<Jeune />} />
          <Route path="Parametres" element={<Parametres />} />
          <Route path="AbsencesEncodage" element={<AbsencesEncodage />} />
        </Routes>
      ) : (
        <AccesRefuse />
      )}
    </div>
  )
}

export default Main
