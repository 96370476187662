import React, { useEffect, useState } from 'react'
import Titre from '../components/Titre.jsx'
import { api, apiDelete, apiPost, apiPut } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import {
  Button,
  Collapse,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Radio,
  Space,
  Table,
  Typography,
} from 'antd'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
import ParametresDates from '../components/ParametresDates.jsx'
import ParametresAbsences from '../components/ParametresAbsences.jsx'

dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const Parametres = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'id',
    'token',
    'cours_fav',
  ])
  const [dates, setDates] = useState([])
  const [date, setDate] = useState({ nom: null, date: [] })
  const [openModalDates, setOpenModalDates] = useState(false)
  const [cours, setCours] = useState([])
  const [profs, setProfs] = useState([])
  const [rangeDate, setRangeDate] = useState('mono')
  const [loadingDates, setLoadingDates] = useState(false)

  const columnsDates = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => {
        if (record.date.length > 1) {
          return `du ${dayjs(record.date[0]).format(dateFormat)} au ${dayjs(
            record.date[1]
          ).format(dateFormat)}`
        } else {
          return `le ${dayjs(record.date[0]).format(dateFormat)}`
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editDate(record)} icon={<EditOutlined />} />
          <Button
            onClick={() => deleteDate(record.id)}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ]

  const fetchDates = async () => {
    try {
      const response = await api(`dates`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      const simplifiedArray = response.data.map((item) => {
        return {
          nom: item.datas.nom,
          date: item.datas.date,
          id: item.id,
        }
      })
      setDates(simplifiedArray)
    } catch (error) {
      console.error('Erreur lors de la récupération des dates:', error)
    }
  }

  const saveDate = async () => {
    if (date.id === 'new') {
      try {
        await toast.promise(
          apiPost(
            'dates/add',
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            { datas: date }
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalDates(false)
        fetchDates()
      }
    } else {
      try {
        await toast.promise(
          apiPut(
            `dates/update/${date.id}`,
            {
              id: cookies['id'],
              token: cookies['token'],
            },
            { datas: date }
          ),
          {
            pending: 'Envoi des données en cours...', // Message pendant le chargement
            success: 'Données sauvegardées avec succès 👌', // Message de succès
            error: {
              render({ data }) {
                // Retourne un message d'erreur basé sur la réponse de l'API
                return `Erreur lors de la sauvegarde : ${
                  data?.response?.data?.message || 'Veuillez réessayer.'
                }`
              },
            }, // Message d'erreur
          }
        )

        // Mets à jour la référence initiale après la sauvegarde
      } catch (error) {
        console.error("Erreur lors de l'envoi de la date: ", error)
      } finally {
        setOpenModalDates(false)
        fetchDates()
      }
    }
  }
  const editDate = (date) => {
    setDate(date)
    setOpenModalDates(true)
    if (date.date.length > 1) {
      setRangeDate('multi')
    } else {
      setRangeDate('mono')
    }
  }
  const deleteDate = async (dateId) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        apiDelete(
          `dates/delete/${dateId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { id: dateId }
        ),
        {
          pending: 'Suppression en cours...', // Message pendant le chargement
          success: 'Données supprimées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la suppression : ${
                data?.response?.data?.message || 'Veuillez réessayer.'
              }`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la suppression de la date: ', error)
    } finally {
      fetchDates()
    }
  }

  useEffect(() => {
    fetchDates()
  }, [])

  const items = [
    {
      key: 'dates',
      label: 'Congés et jours fériés',
      children: <ParametresDates />,
    },
    {
      key: 'absences',
      label: 'Les absences',
      children: <ParametresAbsences />,
    },
  ]
  return (
    <div style={{ minHeight: '100%', padding: '20px 60px' }}>
      <Titre>Les paramètres</Titre>
      <div className="Fond">
        <Collapse accordion items={items} />
      </div>
    </div>
  )
}

export default Parametres
